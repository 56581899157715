<template>
    <div class="edit">
        <van-nav-bar title="报销凭证详情" left-text="返回" @click-left="back" fixed left-arrow />
        <van-form class="edit_form" label-width="140">
            <!-- 报销人 -->
            <van-field
                label="报销人"
                @click="ownerIdShowCli(1)"
                readonly
                clickable
                required
            >
                <template #input>
                    <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='formshowText.name'></TranslationOpenDataText></span>
                    <span v-else>{{formshowText.name}}</span>
                </template>
            </van-field>
            <div v-if="auditTypeItem.auditType == 2">
                <van-field label="第一审核人" @click="ownerIdShowCli(2)" readonly clickable required :disabled="editForm.reviewProcess > 0">
                    <template #input>
                        <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                :openid='firstCheckerText.name'></TranslationOpenDataText></span>
                        <span v-else>{{ firstCheckerText.name }}</span>
                    </template>
                </van-field>
                <van-field label="第二审核人" @click="ownerIdShowCli(3)" readonly clickable required :disabled="editForm.reviewProcess > 1">
                    <template #input>
                        <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                :openid='secondCheckerText.name'></TranslationOpenDataText></span>
                        <span v-else>{{ secondCheckerText.name }}</span>
                    </template>
                </van-field>
            </div>
            <van-popup v-model="ownerIdShow" position="bottom" v-if="false">
                <van-picker
                    value-key="name"
                    show-toolbar
                    :columns="userList"
                    @confirm="ownerIdChange"
                    @cancel="
                        ownerIdShow = false;
                        $forceUpdate();
                    "
                />
            </van-popup>
            <!-- 填报日期 -->
            <van-field
                v-model="editForm.createDate"
                label="填报日期"
                @click="createDateShow = true"
                readonly
                clickable
                required
            ></van-field>
            <van-popup v-model="createDateShow" position="bottom" v-if="canEdit">
                <van-datetime-picker
                    type="date"
                    title="选择填报日期"
                    @confirm="createDateChange"
                    @cancel="
                        createDateShow = false;
                        $forceUpdate();
                    "
                    v-model="currentDate1"
                    :min-date="minDate"
                    :max-date="maxDate"
                />
            </van-popup>
            <!-- 发票张数 -->
            <van-field label="发票张数" :readonly="!canEdit" v-if="user.timeType.easyExpense==0 && editForm.type != 14454">
                <template #input>
                    <van-stepper v-model="editForm.ticketNum" :disabled="!canEdit" disable-input @plus="ticNumChange(1)" @minus="ticNumChange(0)" />
                </template>
            </van-field>
            <!-- 费用类型 -->
            <van-field v-model="editForm.type" label="费用主类型" @click="typeShow = true" readonly clickable>
                <template #input>{{expenseMainType.text}}</template>
            </van-field>
            <van-popup v-model="typeShow" position="bottom" v-if="canEdit">
                <van-picker
                show-toolbar
                :columns="typeList"
                @confirm="typeChange"
                @cancel="typeShow = false;$forceUpdate();"/>
            </van-popup>
            <van-field label="设备归属人" @click="equipmentOwnershipVisable = true" v-if="editForm.type == 14454">
                <template #input>
                    {{ equipmentOwner.name }}
                </template>
            </van-field>
            <van-popup v-model="equipmentOwnershipVisable" position="bottom">
                <van-picker show-toolbar :columns="equipmentOwnerList" @confirm="deviceTypeChange"
                    @cancel="equipmentOwnershipVisable = false; $forceUpdate();" />
            </van-popup>
            <!-- 备注 -->
            <van-field v-model="editForm.remark" label="备注" :readonly="!canEdit" type="textarea"></van-field>
            <!-- 发票 -->
            <van-field label="发票" readonly>
                <template #input
                    >总费用： ￥{{ totalCost }}</template
                >
            </van-field>
            <div class="invoice" v-if="invoiceList.length != 0">
                <div
                    v-for="(item, index) in invoiceList"
                    :key="item.id"
                    style="position: relative"
                    :class="index == 0 ? '' : 'invoice_item'"
                >
                    <van-icon
                        name="delete-o"
                        class="deletebtn"
                        @click="deleteInvoice(index)"
                        v-if="canEdit && index != 0"
                    />
                    <van-field
                        label="所属项目："
                        v-model="item.projectId"
                        @click="(in_projectShow = true), (invoiceIndex = index)"
                        readonly
                        clickable
                        required
                    >
                        <template #input>{{
                            formshowText.inProjectName[index]
                        }}</template>
                    </van-field>
                    <van-field
                        label="费用日期："
                        v-model="item.happenDate"
                        @click="(in_dateShow = true), (invoiceIndex = index)"
                        readonly
                        clickable
                        required
                    ></van-field>
                    <van-field
                        label="发票种类："
                        v-model="item.invoiceType"
                        @click="(in_typeShow = true), (invoiceIndex = index)"
                        readonly
                        clickable
                        required
                        v-if="user.timeType.easyExpense==0"
                    >
                        <template #input>{{
                            inTypeList[item.invoiceType]
                        }}</template>
                    </van-field>
                    <van-field
                        label="费用类型："
                        v-model="item.expenseType"
                        @click="(in_exTypeShow = true), (invoiceIndex = index)"
                        readonly
                        clickable
                        required
                    ></van-field>
                    <van-field
                        :label="`${user.timeType.easyExpense==0?'费用金额（含税）':'费用金额'}：`"
                        v-model="item.amount"
                        type="number"
                        :readonly="!canEdit"
                        required
                    ></van-field>
                    <van-field
                        label="发票号："
                        v-model="item.invoiceNo"
                        readonly
                        v-if="user.timeType.easyExpense==0"
                    ></van-field>
                    <van-field
                        label="税率%："
                        v-model="item.taxPercent"
                        :readonly="!canEdit"
                        v-if="user.timeType.easyExpense==0"
                    ></van-field>
                    <van-field
                        label="税额："
                        readonly
                        v-if="user.timeType.easyExpense==0"
                    ><template #input>￥{{getTaxValue(item.amount,item.taxPercent)}}</template></van-field>
                    <van-field label="备注：" v-model="item.remark" :readonly="!canEdit"></van-field>
                    <van-field
                        @click="invoiceIndex = index"
                        :clickable="!canEdit"
                        label-width="0em"
                    >
                        <template #input>
                            <div class="imgListVan">
                                <div>报销凭证：</div>
                                <div class="vanUploaderImg">
                                    <!-- <van-uploader
                                        v-model="uploader[index]"
                                        :before-read="beforeRead"
                                        :after-read="afterRead"
                                        @delete="item.pic = null"
                                        :max-count="1"
                                        :disabled="!canEdit"
                                        :deletable="canEdit"
                                    /> -->
                                    <van-uploader
                                        v-model="uploader[index]"
                                        :before-read="beforeRead"
                                        :after-read="afterRead"
                                        @delete="(file, Item) => {deteleImg(file, Item, index)}"
                                        :max-count="10"
                                        :disabled="!canEdit"
                                        :deletable="canEdit"
                                        multiple
                                    />
                                </div>
                            </div>
                        </template>
                    </van-field>
                    <van-field
                        label="状态" v-if="user.companyId != mechanicalCompanyId">
                        <template #input>
                            <span :class="statusClass[item.status]">{{
                                    statusList[item.status] }}</span>
                        </template>
                    </van-field>
                    <van-field
                        label="审核人" v-if="item.auditorName && user.companyId != mechanicalCompanyId">
                        <template #input>
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.auditorName'></TranslationOpenDataText></span>
                            <span v-else>{{item.auditorName}}</span>
                        </template>
                    </van-field>
                    <van-field
                        label="操作" v-if="isAudit && item.isIncharger && item.status == 1"
                    >
                    <template #input>
                        <div  style="float:right;">
                            <van-button  type="info" :loading="item.approveLoading"
                                @click.stop="approveSingle(item)">通过</van-button>
                            <van-button style="margin-left:25px" type="danger" :loading="item.approveLoading"
                                @click.stop="denySingle(item)">驳回</van-button>
                        </div>
                    </template>
                    </van-field>
                    
                </div>
            </div>
            <div class="addinvoice" v-if="canEdit"><van-button size="small" icon="plus" type="info" plain hairline @click="addInvoice">添加发票</van-button></div>
            <!-- 发票-popup -->
            <span>
                <!-- 所属项目 -->
                <van-popup v-model="in_projectShow" position="bottom" v-if="canEdit">
                    <van-picker
                        value-key="projectName"
                        show-toolbar
                        :columns="inProjectList"
                        @confirm="inProjectChange"
                        @cancel="
                            in_projectShow = false;
                            $forceUpdate();
                        "
                    />
                </van-popup>
                <!-- 费用日期 -->
                <van-popup v-model="in_dateShow" position="bottom" v-if="canEdit">
                    <van-datetime-picker
                        type="date"
                        title="选择费用日期"
                        v-model="currentDate2"
                        @confirm="inDateChange"
                        @cancel="
                            in_dateShow = false;
                            $forceUpdate();
                        "
                        :min-date="minDate"
                        :max-date="maxDate"
                    />
                </van-popup>
                <!-- 发票种类 -->
                <van-popup v-model="in_typeShow" position="bottom" v-if="canEdit && user.timeType.easyExpense==0" >
                    <van-picker
                        show-toolbar
                        :columns="inTypeList"
                        @confirm="inTypeChange"
                        @cancel="
                            in_typeShow = false;
                            $forceUpdate();
                        "
                    />
                </van-popup>
                <!-- 费用类型 -->
                <van-popup v-model="in_exTypeShow" position="bottom" v-if="canEdit">
                    <van-picker
                        value-key="typeName"
                        show-toolbar
                        :columns="inexTypeList"
                        @confirm="inexTypeChange"
                        @cancel="
                            in_exTypeShow = false;
                            $forceUpdate();
                        "
                    />
                </van-popup>
            </span>
        </van-form>
        <!-- 提交 -->
        <div class="form_btn" style="position: fixed; bottom: 0px; width: 100%" v-if="canEdit">
            <div style="padding-bottom: 10px">
                <van-button
                    square
                    block
                    type="info"
                    @click="submitExpense"
                    :loading="confirmLoading"
                    style="width: 100%; float: left"
                    >提交</van-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            statusList: ['审核通过', '待审核', '已驳回', '已撤销'],
            statusClass: ['', 'waiting', 'rejected', ''],
            isAudit: false,
            user: JSON.parse(localStorage.userInfo),
            canEdit: this.$route.params.canEdit,
            currentDate1 : new Date(),
            currentDate2 : new Date(),
            minDate: new Date(2020,0,1),
            maxDate: new Date(2025,11,31),
            editForm: {
                ownerId: '',
                createDate: '',
                ticketNum: 0,
                type: 0,
                remark: ''
            },
            invoiceList: [],
            confirmLoading: false,

            formshowText: {
                name: '',
                inProjectName: []
            },
            ownerIdShow: false,
            userList: [],
            createDateShow: false,
            typeShow: false,
            typeList: ['一般','差旅','外包'],

            invoiceIndex: 0,
            in_projectShow: false,
            inProjectList: [],
            in_dateShow: false,
            in_typeShow: false,
            inTypeList: ['增值税专用发票','增值税普通发票'],
            in_exTypeShow: false,
            allexTypeList: [],
            inexTypeList: [],
            uploader: [[]],
            expenseMainType:{
                text:''
            },
            firstCheckerText: {},
            secondCheckerText: {},
            auditTypeItem: {},
            mechanicalCompanyId: 4811,
            equipmentOwnerList: [],
            equipmentOwner: {},
            equipmentOwnershipVisable: false
        }
    },
    computed: {
        totalCost(){
            let costnum = 0
            for(let i in this.invoiceList){
                costnum += (this.invoiceList[i].amount ? parseFloat(this.invoiceList[i].amount) : 0)
            }
            return costnum.toFixed(2)
        }
    },
    filters: {
        numtosum(value) {
            if (value == undefined || !value) return '0.00'
            value = value.toFixed(2)
            const intPart = Math.trunc(value)
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            let floatPart = '.00'
            const valueArray = value.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
            floatPart = valueArray[1].toString() // 取得小数部分
            return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        },
    },
    mounted() {
        console.log(this.$route.params);
        this.isAudit = this.$route.params.isAudit;
        this.getAuditType()
        this.getDetail()
        if(this.canEdit){
            this.getProjectList()
        }
        this.getExpensMainTypes()

        if(this.user.companyId == this.mechanicalCompanyId) {
            this.getEquipmentOwnerList()
        }
    },
    methods: {
        deviceTypeChange(value) {
            console.log(value)
            this.equipmentOwner = value
            this.equipmentOwnershipVisable = false
        },
        getEquipmentOwnerList() {
            this.$axios.post("/equipment-owner/getList", {})
            .then(res => {
                if (res.code == "ok") {
                    this.equipmentOwnerList = (res.data || []).map(item => {
                        return {
                            name: item.equipmentOwner,
                            id: item.id,
                            text: item.equipmentOwner
                        };
                    });
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        back(){
            history.back();
        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        getTaxValue(amount,percent){
            let per = percent / 100
            let amo = amount / (1 + per)*per
            return amo.toFixed(2)
        },
        // costCount(){
        //     let costnum = 0
        //     for(let i in this.invoiceList){
        //         costnum += this.invoiceList[i].amount*1
        //     }
        //     this.totalCost = costnum
        // },

        ownerIdChange(value,key){
            this.editForm.ownerId = value.id
            this.formshowText.name = value.name
            this.ownerIdShow = false
        },
        createDateChange(value,key){
            this.editForm.createDate = this.formatDate(value)
            this.createDateShow = false
        },
        typeChange(value,key){
            this.editForm.type = value.id
            this.expenseMainType.text=value.name
            this.typeShow = false
            for(let i in this.invoiceList){
                    this.invoiceList[i].expenseType=''
                }
                console.log(this.editForm.type)
            this.inexTypeList = this.allexTypeList.filter(a=>a.mainType == this.editForm.type)
        },
        ticNumChange(value){
            if(value){
                this.invoiceList.push({
                    projectId: '',
                    happenDate: '',
                    invoiceType: '',
                    expenseType: '',
                    amount: '',
                    invoiceNo: '',
                    taxPercent: '',
                    taxValue: '',
                    remark: '',
                    pic: '',
                })
            }else{
                this.invoiceList.pop()
            }
        },
        getExpensMainTypes(){
            this.$axios.post("/expense-main-type/list", {})
            .then(res => {
                if(res.code == "ok") {
                    for(var i in res.data) {
                        res.data[i].text = res.data[i].name
                    }
                    this.typeList=res.data
                    if(!this.editForm.type) {
                        this.expenseMainType.text=res.data[0].name
                        this.editForm.type=res.data[0].id
                    }
                    this.getExTypeList
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        addInvoice(){
            this.invoiceList.push({
                projectId: '',
                happenDate: '',
                invoiceType: '',
                expenseType: '',
                amount: '',
                invoiceNo: '',
                taxPercent: '',
                taxValue: '',
                remark: '',
                pic: '',
            })
            this.uploader.push([])
            this.editForm.ticketNum = this.invoiceList.length
        },
        deleteInvoice(index){
            this.invoiceList.splice(index,1)
            this.editForm.ticketNum = this.invoiceList.length
            this.uploader.splice(index,1)
        },
        inProjectChange(value,key){
            this.formshowText.inProjectName[this.invoiceIndex] = value.projectName
            this.invoiceList[this.invoiceIndex].projectId = value.id
            this.in_projectShow = false
        },
        inDateChange(value,key){
            this.invoiceList[this.invoiceIndex].happenDate = this.formatDate(value)
            this.in_dateShow = false
        },
        inTypeChange(value,key){
            this.invoiceList[this.invoiceIndex].invoiceType = key
            this.in_typeShow = false
        },
        inexTypeChange(value,key){
            this.invoiceList[this.invoiceIndex].expenseType = value.typeName
            this.in_exTypeShow = false
        },

        beforeRead(file){
            // if(file.type != 'image/jpeg' && file.type != 'image/png'){
            //     this.$toast.fail('请选择jpg或png格式的图片')
            //     return false
            // }
            // return true

            let newFile = Array.isArray(file) ? file : [file]
            for(var i in newFile) {
                const fileItem = newFile[i]
                if (fileItem.type != 'image/jpeg' && fileItem.type != 'image/png' && fileItem.type != 'image/jpg') {
                    this.$toast.fail('请选择jpg或png格式的图片')
                    return false
                }
            }
            return true
        },
        afterRead(file){
            // let formData = new FormData();
            // formData.append("multipartFile", file.file);
            // this.$axios.post("/common/uploadFile", formData)
            // .then(res => {
            //     if(res.code == "ok") {
            //         this.invoiceList[this.invoiceIndex].pic = res.data
            //     } else {
            //         this.$toast.fail('上传失败');
            //         this.uploader[this.invoiceIndex] = []
            //     }
            // }).catch(err=> {this.$toast.clear();console.log(err);this.uploader[this.invoiceIndex]=[]});

            let newFile = Array.isArray(file) ? file : [file]
            let formData = new FormData();
            for(var i in newFile) {
                formData.append("multipartFiles", newFile[i].file);
            }
            this.$axios.post("/common/uploadFileArray", formData)
            .then(res => {
                if (res.code == "ok") {
                    let newPic = this.invoiceList[this.invoiceIndex].pic || []
                    let newData = res.data.split(';')
                    this.invoiceList[this.invoiceIndex].pic = [...newPic, ...newData]
                    console.log(this.invoiceList[this.invoiceIndex].pic, '<=== 合计图片')
                } else {
                    this.$toast.fail('上传失败');
                    this.uploader[this.invoiceIndex] = []
                }
            }).catch(err => { this.$toast.clear(); console.log(err); this.uploader[this.invoiceIndex] = [] });
        },

        // 删除图片
        deteleImg(file, item, arrIndex) {
            let index = item.index
            this.invoiceList[arrIndex].pic = this.invoiceList[arrIndex].pic.filter((item,i) => i != index)
        },

        submitExpense(){
            if(!this.editForm.ownerId){
                this.$toast.fail('请选择报销人')
                return
            }
            if(!this.editForm.createDate){
                this.$toast.fail('请选择填报日期')
                return
            }

            if(this.auditTypeItem.auditType == 2) {
                if(!this.firstCheckerText.id || !this.secondCheckerText.id) {
                    this.$toast.fail(`${!this.firstCheckerText.id ? '请选择第一审核人' : '请选择第二审核人'}`)
                    return
                } else {
                    this.editForm.firstCheckerId = this.firstCheckerText.id
                    this.editForm.secondCheckerId = this.secondCheckerText.id
                }
            }

            let required1 = false
            let required2 = false
            let required3 = false
            let required4 = false
            let required5 = false
            for(let i in this.invoiceList){
                if(!this.invoiceList[i].projectId){
                    required1 = '所属项目'
                }
                if(!this.invoiceList[i].happenDate){
                    required2 = '费用日期'
                }
                if(!this.invoiceList[i].invoiceType){
                    required3 = '发票种类'
                }
                if(!this.invoiceList[i].expenseType){
                    required4 = '费用类型'
                }
                if(!this.invoiceList[i].amount){
                    required5 = '费用金额（含税）'
                }
            }
            if(required1 || required2 || required3 || required4 || required5){
                let requiredStr = (required1 ? required1 + '、' : '')
                                + (required2 ? required2 + '、' : '')
                                + (required3 ? required3 + '、' : '')
                                + (required4 ? required4 + '、' : '')
                                + (required5 ? required5 + '、' : '')
                requiredStr = requiredStr.substring(0,requiredStr.length - 1)
                this.$toast.fail('请添加发票的[' + requiredStr + ']')
                return
            }

            let newList = JSON.parse(JSON.stringify(this.invoiceList))
            for(let i in newList) {
                if(newList[i].pic) {
                    newList[i].pic = newList[i].pic.join(',')
                }
            }

            delete this.editForm.invoiceList
            // this.editForm.items = JSON.stringify(this.invoiceList)
            this.editForm.items = JSON.stringify(newList)
            this.editForm.totalAmount = this.totalCost
            this.confirmLoading = true
            this.$axios.post("/expense-sheet/add", this.editForm)
            .then(res => {
                if(res.code == "ok") {
                    this.$toast.success('提交成功')
                    this.confirmLoading = false
                    this.back()
                } else {
                    this.confirmLoading = false
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err=> {this.confirmLoading = false;this.$toast.clear();console.log(err)});
        },


        getDetail(){
            this.$axios.post("/expense-sheet/getDetail", {id: this.$route.params.id})
            .then(res => {
                if(res.code == "ok") {
                    this.editForm = res.data
                    if(this.canEdit){this.getExTypeList()}
                    this.formshowText.name = res.data.ownerName
                    this.totalCost = res.data.totalAmount
                    this.invoiceList = res.data.invoiceList
                    this.expenseMainType.text = res.data.expenseMainTypeName
                    this.equipmentOwner = {
                        id: res.data.equipmentOwnerId,
                        text: res.data.equipmentOwnerName,
                        name: res.data.equipmentOwnerName
                    }
                    if(this.auditTypeItem.auditType == 2) {
                        this.firstCheckerText = {
                            id: res.data.firstCheckerId,
                            name: res.data.firstCheckerName
                        }
                        this.secondCheckerText = {
                            id: res.data.secondCheckerId,
                            name: res.data.secondCheckerName
                        }
                    }
                    for(let i in res.data.invoiceList){
                        this.formshowText.inProjectName.push(res.data.invoiceList[i].projectName)
                        res.data.invoiceList[i].approveLoading = false;
                        if(res.data.invoiceList[i].pic){
                            // this.uploader[i] = [{url:'/upload/' + res.data.invoiceList[i].pic}]
                            let pic = res.data.invoiceList[i].pic
                            let picArr = pic.split(',').map(item => {
                                return { url: `/upload/${item}`}
                                // return { url: `http://47.101.180.183/upload/${item}`}
                            })
                            res.data.invoiceList[i].pic = pic.split(',').map(item => item)
                            this.uploader[i] = picArr
                            console.log('每一项的图片', picArr)
                        }else{
                            this.uploader[i] = []
                        }
                        
                    }
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getUserList(){
            this.$axios.post("/user/getSimpleActiveUserList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.userList = res.data
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getProjectList(){
            this.$axios.post("/project/getProjectList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.inProjectList = res.data
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getExTypeList(){
            this.$axios.post("/expense-type/getList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.allexTypeList = res.data
                    this.inexTypeList = this.allexTypeList.filter(a=>a.mainType == this.editForm.type)
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        // 单据审核
        approveSingle(item) {
            item.approveLoading = true;
            this.$axios.post("/expense-sheet/approveSingleProject", { id: item.id })
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.success('已通过')
                        item.approveLoading = false
                        this.getDetail();
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
       
        denySingle(item) {
            item.approveLoading = true;
            this.$axios.post("/expense-sheet/denySingleProject", {id: item.id})
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.success('已驳回')
                        item.approveLoading = false
                        this.getDetail();
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        getAuditType() {
            this.$axios.post("/expense-audit-setting/get", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.auditTypeItem = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error"
                        });
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        ownerIdShowCli(type) {
            this.ownerIdShowType = type
            this.userRadio = ''
            this.ownerIdShow = true
        }
    },
};
</script>

<style lang="less" scoped>
.edit{
    margin-top: 46px;
    overflow: auto;
    .form_btn{
        z-index: 1000;
    }
    .edit_form{
        .userCheckbox {
            padding: 10px;
        }
        padding-bottom: 46px;
        .invoice{
            border: 0.5px solid rgb(135, 195, 255);
            margin: 0.2rem;
            .deletebtn{
                position: absolute;
                z-index: 1000;
                font-size: 24px;
                right: 0.08rem;
                top: 0.08rem;
                color: #c03131;
            }
            .invoice_item{
                border-top: 0.5px solid rgb(135, 195, 255);
            }
        }
        .addinvoice{
                padding: 0 0.3rem 0.3rem;
                margin-top: 0.2rem;
                text-align: right;
            }
    }
    .imgListVan {
        display: flex;
        flex-direction: column;
    }
    .vanUploaderImg {
        // display: flex;
        margin-top: 6px;
        /deep/ .van-uploader__preview-image {
            width: 70px;
            height: 70px;
        }
        /deep/ .van-uploader__upload {
            width: 70px;
            height: 70px;
        }
    }
}
.waiting {
    color: orange;
}

.rejected {
    color: red;
}
</style>
<style>
.edit_form .invoice .van-field__label{
    color: #999;
}
.edit_form .invoice .van-field__control{
    color: #999;
}
</style>